import { initializeApp } from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAmk3e22EplIFcKiectlRBrDnoQwOO83lQ",
  authDomain: "peach-ring-labs.firebaseapp.com",
  projectId: "peach-ring-labs",
  storageBucket: "peach-ring-labs.appspot.com",
  messagingSenderId: "185803825101",
  appId: "1:185803825101:web:bcd7bc26e0ad425c1f2e84"
};

export const app = initializeApp(firebaseConfig);