<template>
<div class="main">

  <div class="menu">
    <div @click="$router.push('/')" class="logo-div">
      <h1 class="logo">Peach Ring Labs</h1>
      <svg class="peach-logo" width="50" height="50" viewBox="0 0 51 51" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25ZM13.9719 25C13.9719 31.0907 18.9093 36.0281 25 36.0281C31.0907 36.0281 36.0281 31.0907 36.0281 25C36.0281 18.9093 31.0907 13.9719 25 13.9719C18.9093 13.9719 13.9719 18.9093 13.9719 25Z"
          fill="url(#paint0_linear)" />
        <circle cx="25" cy="25" r="17" fill="white" fill-opacity="0.4" />
        <defs>
          <linearGradient id="paint0_linear" x1="0" y1="0" x2="50" y2="50"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#FF3434" />
            <stop offset="1" stop-color="#FFD466" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="menu-links-div">
      <h2 @click="$router.push('/')" class="menu-link" id="brands">Brands</h2>
      <h3 @click="$router.push('')" class="menu-link" id="creators" style="color: var(--primary-red);">Creators</h3>
      <!-- <h1 @click="$router.push('catalog')" class="menu-link">Catalog</h1> -->
      <h3 @click="openContactForm()" class="menu-link">Contact</h3>
    </div>
    <img @click="openModal()" id="menu-icon" src="@/assets/tabler-icon-menu.svg" alt="menu">
  </div>

  <div @click="closeModal()" id="myModal" class="modal">
    <!-- Modal content -->
    <div class="modal-content">
      <span @click="closeModal()" class="close">&times;</span>
      <h1 @click="$router.push('/')" class="modal-link">Brands</h1>
      <h1 @click="$router.push('creators')" class="modal-link" style="color: var(--primary-red);">Creators</h1>
      <!-- <h1 @click="$router.push('catalog')" class="modal-link">Catalog</h1> -->
      <h1 @click="openContactForm()" class="modal-link">Contact</h1>
    </div>
  </div>

  <div class="section" id="section-1">

    <div class="intro">
      <div class="intro-left">
        <h1 class="opener-text"><span class="gradient-text">Get paid</span> to <br>create content</h1>
        <h3 class="sub-text">
          Make money by producing stunning short-form videos for brands.
        </h3>
        <button @click="openCreatorApp()" class="CTA-button">
          <h3>Apply</h3>
          <img class="chevron" src="@/assets/tabler-icon-chevron-right.svg" alt="arrow">
        </button>
      </div>

      <div id="video-wrapper">
        <video playsinline muted loop no-controls autoplay id="video"
          src="https://res.cloudinary.com/mediacdnmain/video/upload/v1617580960/PRL_v5pgqh.mp4">
        </video>
      </div>
    </div>

  </div>

  <div class="section" id="section-2">

    <div class="step">
      <div class="circle">
        <img class="step-icon" src="@/assets/tabler-icon-list-check.svg" alt="download">
      </div>
      <div class="step-number">
        <h3>Accept offers</h3>
      </div>
      <p>Orders include short demos and reviews for brands.</p>
    </div>

    <div class="step">
      <div class="circle">
        <img class="step-icon" src="@/assets/tabler-icon-video.svg" alt="download">
      </div>
      <div class="step-number">
        <h3>Create content</h3>
      </div>
      <p>Shoot with creative instructions once the product arrives.</p>
    </div>

    <div class="step">
      <div class="circle">
        <img class="step-icon" src="@/assets/tabler-icon-cash.svg" alt="download">
      </div>
      <div class="step-number">
        <h3>Get paid</h3>
      </div>
      <p>Collect earnings upon media approval.</p>
    </div>

  </div>

  <div class="section">
    <div class="feature-div" id="creators-card-div">
      <h1 class="feature-text" id="f4-text">No posts to your socials needed. Create when you want,
        and have fun doing it.</h1>
      <div class="final-card-buttons">
        <button @click="openCreatorApp()" class="final-card-button" id="btn-1">
          <h3>Apply</h3>
          <img class="chevron" src="@/assets/tabler-icon-chevron-right-black.svg" alt="arrow">
        </button>
        <button @click="openContactForm()" class="final-card-button" id="btn-2">
          <h3>Contact</h3>
        </button>
      </div>
    </div>
  </div>

  <h4 class="footer-text">© 2021 Peach Ring Labs. All rights reserved.</h4>

</div>
</template>

<script defer >
export default {
  name: 'creators',

  data: () => ({}),

  props: {
    msg: String,
  },

  methods: {
    openCreatorApp() {
      window.open("https://forms.gle/Bg8359SRboLawRK79", "_blank");
    },

    openContactForm() {
      window.open("https://forms.gle/DUi3wfZ9PopeSkf86", "_blank");
    },

    openModal() {
      document.getElementById('myModal').style.display = "block";
    },

    closeModal() {
      document.getElementById('myModal').style.display = "none";
    }
  },

  mounted() {
    window.onclick = function(event) {
      if (event.target == document.getElementById('myModal')) {
        document.getElementById('myModal').style.display = "none";
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Lato:wght@300;400;700;900&display=swap');


@import '../styles/menu.css';
@import '../styles/intro.css';
@import '../styles/introsteps.css';

</style>
