<template>
<div class="main">

  <div class="menu">
    <div @click="$router.push('/')" class="logo-div">
      <h1 class="logo">Peach Ring Labs</h1>
      <svg class="peach-logo" width="50" height="50" viewBox="0 0 51 51" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25ZM13.9719 25C13.9719 31.0907 18.9093 36.0281 25 36.0281C31.0907 36.0281 36.0281 31.0907 36.0281 25C36.0281 18.9093 31.0907 13.9719 25 13.9719C18.9093 13.9719 13.9719 18.9093 13.9719 25Z"
          fill="url(#paint0_linear)" />
        <circle cx="25" cy="25" r="17" fill="white" fill-opacity="0.4" />
        <defs>
          <linearGradient id="paint0_linear" x1="0" y1="0" x2="50" y2="50"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#FF3434" />
            <stop offset="1" stop-color="#FFD466" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="menu-links-div">
      <h2 @click="$router.push('/')" class="menu-link" id="brands">Brands</h2>
      <h3 @click="$router.push('creators')" class="menu-link" id="creators">Creators</h3>
      <h1 @click="$router.push('catalog')" class="menu-link" style="color: var(--primary-red);">Catalog</h1>
      <h3 @click="openContactForm()" class="menu-link">Contact</h3>
    </div>
    <img @click="openModal()" id="menu-icon" src="@/assets/tabler-icon-menu.svg" alt="menu">
  </div>

  <div @click="closeModal()" id="myModal" class="modal">
    <!-- Modal content -->
    <div class="modal-content">
      <span @click="closeModal()" class="close">&times;</span>
      <h1 @click="$router.push('/')" class="modal-link">Brands</h1>
      <h1 @click="$router.push('creators')" class="modal-link">Creators</h1>
      <h1 @click="$router.push('catalog')" class="modal-link" style="color: var(--primary-red);">Catalog</h1>
      <h1 @click="openContactForm()" class="modal-link">Contact</h1>
    </div>
  </div>

  <h1 style="text-align: center; margin-top: 2rem; margin-bottom: 1rem">Find the best creator for your product video.</h1>
  <div style="display: flex; align-items: center">
    <h2 style="text-align: center; margin-bottom: 2rem; color: var(--secondary-gray)"> <a target="_blank" href="https://www.w3schools.com">Register</a> to get a  media request form.</h2>
  </div>

  <!-- <div class="filters">
    <button @click="modalOpen($index)" class="CTA-button" id="info">
    <p><b>Male</b></p>
    </button>
    <button @click="modalOpen($index)" class="CTA-button" id="info">
      <p><b>Female</b></p>
    </button>
  </div> -->

  <div class="grid">
    <div v-for="item in Creators" :key="item.id" class="cell" id="cell">
      <div id="video-wrapper">
        <video playsinline preload="metadat" muted controls id="video" :src=item.video type="video/mp4"></video>
        <!-- <video onmouseover="this.play()" onmouseout="this.pause();this.currentTime=0;" playsinline muted controls id="video" :src=item.video></video> -->
      </div>
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <h1 class="creator-name">{{item.first}} {{item.last}}</h1>
      </div>
      <p class="creator-id">{{item.id}}</p>
      <!-- <button @click="modalOpen($index)" class="CTA-button" id="info">
          <p><b>View creator details</b></p>
      </button> -->
    </div>
  </div>

  <h4 class="footer-text">© 2021 Peach Ring Labs. All rights reserved.</h4>
</div>
</template>

<script defer>

import {app} from '@/db.js'
import { query, where, getFirestore, collection, getDocs } from 'firebase/firestore/lite';

export default {
  
  name: 'catalog',

  data: () => {
    return {
      Creators: [],
    }
  },

  async created() {
      const db = getFirestore(app);
      const q = query(collection(db, "creators"), where("available", "==", true));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        this.Creators.push({
          id: doc.data().id,
          first: doc.data().first,
          last: doc.data().last.charAt(0) + '.',
          gender: doc.data().gender,
          video: doc.data().video,
        })
      });
  },

  methods: {
    openContactForm() {
      window.open("https://forms.gle/DUi3wfZ9PopeSkf86", "_blank");
    },
    openModal() {
      document.getElementById('myModal').style.display = "block";
    },
    closeModal() {
      document.getElementById('myModal').style.display = "none";
    },
  },

  mounted() {
    window.onclick = function(event) {
      if (event.target == document.getElementById('myModal')) {
        document.getElementById('myModal').style.display = "none";
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Lato:wght@300;400;700;900&display=swap');

@import '../styles/menu.css';
@import '../styles/intro.css';
@import '../styles/introsteps.css';

.grid {
  min-height: 700px;
  align-items: flex-start;
  justify-items: center;
  width: 100%;
  --auto-grid-min-size: 300px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 2rem;
}

#video-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.creator-name {
  font-size: 1.5rem;
  margin-top: 0.3rem;
}

.creator-id {
  color: var(--secondary-gray);
  font-size: 1rem;
  margin: 0.3rem 0;
}

/* #info {
  margin: 0;
  padding: 0.45rem 0;
  width: 100%;
  color: white;
  border-radius: 100px;
} */

.filters {
  display: flex;
  margin: 2rem auto;
}

#info {
  margin: 0;
  padding: 0.45rem 0;
  width: 120px;
  border-radius: 100px;
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--primary-red);
  color: var(--page-white);
  background-color: var(--page-white);
  background-color: var(--primary-red);
  border: 3px solid var(--primary-red);
}

#info:last-child {
  margin-left: 10px;
}
</style>
