import Vue from "vue";
import VueRouter from "vue-router";
import Creators from "../views/Creators.vue";
import Brands from "../views/Brands.vue";
import Catalog from "../views/Catalog.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "brands",
    component: Brands
  },
  {
    path: "/creators",
    name: "creators",
    component: Creators,
  },
  {
    path: "/catalog",
    name: "catalog",
    component: Catalog,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
